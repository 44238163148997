<template>
    <div id="box">
        <Header :headerIndex="4" :nextLevel="2"></Header>
        <div class="container">
            <div class="flex-box flex-col-start ptb30 m1340 flex-col-stretch">
                <LeftNav :leftIndex="2"></LeftNav>
                <div class="flex-grow-1 ml20 pt30 plr30 personal coins_account">
                    <div class="flex-box pb20">
                        <div class="fs20 fwb pr20">{{$t('rp')}}</div>
                        <a href="javascript:;" class="tips"></a>
                    </div>
                    <div class="mb40 flex-box flex-between convert_box">
                        <div class="coin_box ">
                            <div class="fs14 col5 pb5">RP</div>
                            <div class="fs24 colb fwb">{{ user.rp | numberToCurrency }}</div>
                        </div>
                        <!-- <a href="javascript:;" class="convert" v-if="hidden" @click="hidden=false">CONVERT</a> -->
                    </div>
                    <template>
                        <template v-if="!hidden" >
                            <div class="fs20 fwb pr20 pb30">CONVERT</div>
                            <div class="fs16 col5 pb10">RP</div>
                            <el-input class="input mb30" v-model="num" :placeholder="'1RP='+coinsrp+'M-Coins'"></el-input>
                            <div class="fs16 col5 pb10">Password</div>
                            <el-input class="input mb30" v-model="password" placeholder="Password"></el-input>
                            <!-- <div class="fs16 col5 pb10">Graphic Verification Code</div> -->
                            <!-- <el-input class="input mb30" placeholder="Perform human-machine identity verification"></el-input> -->
                            <!-- <div id="grecaptcha" class="mb40"></div> -->
                            <a href="javascript:;" class="convert mb40" @click="convert">CONVERT</a>
                            <div class="liiustrate p40 mb40">
                                <div class="fs20 fwb col10 pb20">LIIUSTRATE</div>
                                <div class="fs16 col3">
                                    <div class="">

                                    <div class="" v-html="articledetail"></div>
                                    </div>
                                </div>
                            </div>
                        </template>
                    </template>
                    <div class="fs20 fwb pb20">{{$t('records')}}</div>
                    <div class="title_box flex-box fs16 col3">
                        <div class="title_item">{{$t('date')}}</div>
                        <div class="title_item ">{{$t('type')}}<span class="w110 tc"></span>{{$t('number')}}</div>
                        <!-- <div class="title_item">{{$t('price')}}</div> -->
                    </div>
                    <div class="list_box flex-box fs16 col3" v-for="item in list">
                        <div class="list_item">{{item.createtime_text}} </div>
                        <div class="list_item ">RP<span class="w110 tc">*</span>{{item.num_text}}</div>
                        <!-- <div class="list_item">{{item.coinsnum}}</div> -->
                    </div>
                </div>
            </div>
        </div>
        <Footer></Footer>
    </div>
</template>
<style>
    .hidden{
        display: none;
    }
</style>
<script>
import { numberToCurrency } from "../assets/filters";
import cookie from 'vue-cookies'
export default {
    filters: {
        numberToCurrency
    },
    data() {
        return {
            //人机验证 详情看网址 https://segmentfault.com/a/1190000023733698?sort=newest
            sitekey: "6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI",
            user:'',
            list:{},
            articledetail:'',
            coinsrp:0,
            hidden:true,
            num:'',
            password:''

        };
    },
    mounted() {
        this.loaded();
    },
    created(){
    
		if(this.$route.query.type==2){
            this.hidden=false
        }
        this.initdata()
    },
    methods: {
        config(){
            this.$api.config({}).then((res) => {
                if (res.code == 1) {
                    console.log(88)
                    this.articledetail=res.data.member
                    this.coinsrp=res.data.coinsrp
                } else {
                    // that.$message.error(res.msg);
                    this.$message({
						message: res.msg,
						type: 'error',
						offset: window.screen.height / 2
					})
                }
            })
        },
        submit: function (token) {
            console.log(token);
        },
        convert(){
            if(!this.num){
                // return this.$message.error('请填写兑换数量')
                return this.$message({
						message: "请填写兑换数量",
						type: 'error',
						offset: window.screen.height / 2
					})
            }
            if(!this.password){
                // return this.$message.error('请填写交易密码')
                return this.$message({
						message: "请填写交易密码",
						type: 'error',
						offset: window.screen.height / 2
					})

            }
            var data={num:this.num,password:this.password}
            this.$api.apiRpchange(data).then(ret=>{
                if(ret.code==1){
                    // this.$message.success(ret.msg)
                    this.$message({
                        message:ret.msg,
                        type: 'success',
                        offset: window.screen.height / 2
                    })
                    window.location.reload(true)                 
                }else{
                    // this.$message.error(ret.msg)
                    this.$message({
						message: ret.msg,
						type: 'error',
						offset: window.screen.height / 2
					})
                }
            })
        },
        loaded() {
            setTimeout(() => {
                window.grecaptcha.render("grecaptcha", {
                    sitekey: this.sitekey,
                    callback: this.submit
                });
            }, 200);
        },
        initdata(){
            this.getuser()
            this.loglist()
            this.config()
        },
        getuser(){
            if(cookie.get('sma_token')){
                this.$api.apiUserInfo({}).then(ret=>{
				if(ret.code==1){

					this.user = ret.data.userinfo;
				}
			})
            }
            
        },
        loglist(){
            this.$api.apiRploglist({}).then(ret=>{
                if(ret.code==1){
                    this.list=ret.data
                }
            })
        },
    }
};
</script>
